
import { defineComponent } from "vue";
export default defineComponent({
  name: "MyTestimonial",
  props: {
    name: { type: String, default: "Name" },
    type: {
      type: String,
      default: "Participant",
    },
    description: { type: String, default: "Description" },
    image: { type: String, default: "pedro" },
  },
  methods: {
    getImgUrl(image: string) {
      var images = require.context("@/assets/teachers/", false, /\.png$/);
      return images("./profile_" + image + ".png");
    },
  },
});
