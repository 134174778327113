
import { defineComponent } from "vue";
import MyButton from "@/components/common-elements/Button.vue";
import MyCountdown from "@/components/common-elements/Countdown.vue";
import MyTestimonial from "@/components/common-elements/Testimonial.vue";
// import { Carousel, Slide } from "vue3-carousel";
import jsonData from "@/data/testimonials.json";
import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "MyHomePage",
  components: {
    MyButton,
    MyCountdown,
    MyTestimonial,
  },
  methods: {
    shuffle(array: any) {
      let currentIndex = array.length,
        randomIndex;

      let newArray = [...array];

      // While there remain elements to shuffle.
      while (currentIndex != 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [newArray[currentIndex], newArray[randomIndex]] = [
          newArray[randomIndex],
          newArray[currentIndex],
        ];
      }

      return newArray;
    },
    convertDate(date: string, start = true) {
      const dateExplode = date.split("/");
      let newDate = new Date(
        Number(dateExplode[0]),
        Number(dateExplode[1]) - 1,
        Number(dateExplode[2]),
        0,
        0,
        0
      );
      if (!start) {
        newDate = new Date(
          Number(dateExplode[0]),
          Number(dateExplode[1]) - 1,
          Number(dateExplode[2]) + 1,
          23,
          59,
          59
        );
      }

      return newDate;
    },
  },
  data() {
    const now = new Date();
    const endDate = new Date("03/01/2025");
    const endDate2 = new Date("06/13/2025");
    return {
      btGatheringIndia: {
        type: "route",
        value: "GoaGathering2025",
        gtag: {
          name: "navigate_to",
          category: "goto",
          label: "click",
          value: "goa-gathering",
        },
      },
      btGatheringPortugal: {
        type: "route",
        value: "PortugalGathering2025",
        gtag: {
          name: "navigate_to",
          category: "goto",
          label: "click",
          value: "portugal-gathering",
        },
      },
      btIndia: {
        type: "route",
        value: "MainIndia",
        gtag: {
          name: "navigate_to",
          category: "goto",
          label: "click",
          value: "main_india",
        },
      },
      btMorocco: {
        type: "route",
        value: "MainMorocco",
        gtag: {
          name: "navigate_to",
          category: "goto",
          label: "click",
          value: "main_morocco",
        },
      },
      btPortugal: {
        type: "route",
        value: "MainPortugal",
        gtag: {
          name: "navigate_to",
          category: "goto",
          label: "click",
          value: "main_portugal",
        },
      },
      btBrazil: {
        type: "route",
        value: "MainBrazil",
        gtag: {
          name: "navigate_to",
          category: "goto",
          label: "click",
          value: "main_brazil",
        },
      },
      btCalendar: {
        type: "gcalendar",
        gc: {
          startTime: this.convertDate("2025/06/13"),
          endTime: this.convertDate("2025/06/16", false),
          title: "AYGA Gathering Portugal 2025",
          location: "Alcobaça - Portugal",
          details:
            "<p>Check more Details about AYGA Gathering Portugal 2025 at <a href='https://ayga.site/portugal-gathering'>https://ayga.site/portugal-gathering</a> <br/>3NJ0Y</p>",
        },
        gtag: {
          name: "add_to_gcalendar",
          category: "reminder",
          label: "click",
          value: "ayga_portugal_2024",
        },
      },
      timeToOpen: endDate.getTime() - now.getTime(),
      timeToOpen2: endDate2.getTime() - now.getTime(),
      testemonialList: this.shuffle(jsonData),
    };
  },
});
