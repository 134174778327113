import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomePage from "../views/HomePage.vue";

const titleStart = "AYGA ::";
const DEFAULT_TITLE = titleStart;

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    meta: { title: titleStart + " AcroYoga Gathering" },
  },
  {
    path: "/branding",
    name: "Branding",
    meta: { title: titleStart + " branding" },
    component: () =>
      import(/* webpackChunkName: "branding" */ "../views/Branding.vue"),
  },
  {
    path: "/whatisayga",
    name: "WahtIsAyga",
    meta: { title: titleStart + " What is Ayga" },
    component: () =>
      import(/* webpackChunkName: "what" */ "../views/WhatIsAyga.vue"),
  },
  {
    path: "/portugal-gathering",
    name: "PortugalGathering2025",
    meta: { title: titleStart + " Portugal Gathering 2025" },
    component: () =>
      import(
        /* webpackChunkName: "portugal" */ "../views/PortugalGathering.vue"
      ),
  },
  {
    path: "/main-india",
    name: "MainIndia",
    meta: { title: titleStart + " India Offers" },
    component: () =>
      import(/* webpackChunkName: "india" */ "../views/MainIndia.vue"),
  },
  {
    path: "/main-morocco",
    name: "MainMorocco",
    meta: { title: titleStart + " Morocco Offers" },
    component: () =>
      import(/* webpackChunkName: "morocco" */ "../views/MainMorocco.vue"),
  },
  {
    path: "/main-portugal",
    name: "MainPortugal",
    meta: { title: titleStart + " Portugal Offers" },
    component: () =>
      import(/* webpackChunkName: "portugal" */ "../views/MainPortugal.vue"),
  },
  {
    path: "/main-brazil",
    name: "MainBrazil",
    meta: { title: titleStart + " Brazil Offers" },
    component: () =>
      import(/* webpackChunkName: "brazil" */ "../views/MainBrazil.vue"),
  },
  {
    path: "/goa-gathering",
    name: "GoaGathering2025",
    meta: { title: titleStart + " Goa Gathering 2025" },
    component: () =>
      import(/* webpackChunkName: "ahmedabad" */ "../views/IndiaGathering.vue"),
  },
  {
    path: "/retreat-tamraght",
    name: "RetreatTamraght",
    meta: { title: titleStart + " retreat Tamraght" },
    component: () =>
      import(
        /* webpackChunkName: "branding" */ "../views/retreats/RetreatTamraghtView.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to: any) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  document.title = to.meta.title || DEFAULT_TITLE;
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router;
