import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52551eed"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["btn", _ctx.cclass]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick())),
    type: _ctx.ctype,
    disabled: _ctx.disabledState
  }, _toDisplayString(_ctx.text), 11, _hoisted_1))
}